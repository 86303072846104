export const VALID_STATES = {
  enabledOrPaused: 'Enabled or Paused',
  enabledWithParent: 'Enabled with Parent',
  enabled: 'Enabled',
  paused: 'Paused',
  archived: 'Archived'
};

export const NAME_SEARCH_OPTIONS = {
  exact: 'Exactly Matches',
  phrase: 'Includes',
  beginWith: 'Begins With',
  endWith: 'Ends With',
  notInclude: "Doesn't Include",
  notBeginWith: "Doesn't Begin With",
  notEndWith: "Doesn't End With",
};

export const ADVANCED_FILTER_STATS = {
  defaultBid: 'Ad Group Default Bid',
  spend: 'Ad Spend',
  acos7: 'ACoS 7d',
  acos30: 'ACoS 30d',
  cr7: 'CR 7d',
  cr30: 'CR 30d',
  ctr: 'CTR',
  cpc: 'AVG. CPC',
  impr: 'Impressions',
  clicks: 'Clicks',
  orders30: 'Orders 30d',
  ordersSKU30: 'Orders 30d SameSKU',
  orders7: 'Orders 7d',
  ordersSKU7: 'Orders 7d SameSKU',
  sales7: 'Sales 7d',
  salesSKU7: 'Sales 7d SameSKU',
  sales30: 'Sales 30d',
  salesSKU30: 'Sales 30d SameSKU',
  maxAcos: 'Max.ACoS',
  unitPrice: 'Max.Spend',
  chRate:'Ch.Rate',
  ruleMinCpc: 'Min.Bid',
  ruleMaxCpc: 'Max.Bid',
  ruleClick: 'Action Clicks',
  ruleCr: 'Min.CR',
  ruleCpcIncrement: 'Bid Increment',
  relevancySale: 'Relevancy Trigger'
};

export const HIDDEN_EXPORT_COLUMNS = {
  adGroupId: 'adGroupId',
  campaignId: 'campaignId',
  profit7: 'profit7',
  profit30: 'profit30',
  campaignState: 'campaignState',
  campaignTargeting: 'campaignTargeting',
  ruleActive: 'ruleActive',
};

export const ADVANCED_FILTER_STAT_CRITERIA = {
  gt: 'Greater Than',
  lt: 'Lower Than',
  equal: 'Equal To',
  notEqual: 'Not Equal To',
  gteq: 'Greater Than or Equal To',
  lteq: 'Lower Than or Equal To',
  unset: 'Unset (Null or Zero)',
};

export const ADVANCED_FILTER_LEGACY_AUTOMATION = {
  yes: 'Yes',
  no: 'No',
  unset: 'Unset (Null or Zero)',
};

export const CREATED_AT_IDX = 0;
export const STATE_IDX = 1;
export const CAMPAIGN_NAME_IDX = 2;
export const LEGACY_AUTOMATION_IDX = 3;
// metrics filters
export const METRIC_FILTER_DEFAULTBID_IDX = 4;
export const METRIC_FILTER_SPEND_IDX = 5;
export const METRIC_FILTER_ACOS7_IDX = 6;
export const METRIC_FILTER_ACOS30_IDX = 7;
export const METRIC_FILTER_CR7_IDX = 8;
export const METRIC_FILTER_CR30_IDX = 9;
export const METRIC_FILTER_CTR_IDX = 10;
export const METRIC_FILTER_CPC_IDX = 11;
export const METRIC_FILTER_IMPRESS_IDX = 12;
export const METRIC_FILTER_CLICKS_IDX = 13;
export const METRIC_FILTER_C30D_IDX = 14;
export const METRIC_FILTER_C30DS_IDX = 15;
export const METRIC_FILTER_C7D_IDX = 16; // orders7
export const METRIC_FILTER_C7DS_IDX = 17; // orderssku
export const METRIC_FILTER_S7D_IDX = 18;
export const METRIC_FILTER_S7DS_IDX = 19;
export const METRIC_FILTER_S30D_IDX = 20;
export const METRIC_FILTER_S30DS_IDX = 21;
export const METRIC_FILTER_MAXACOS_IDX = 22;
export const METRIC_FILTER_UNITPRICE_IDX = 23;
export const METRIC_FILTER_CHRATE_IDX = 0;
export const METRIC_FILTER_RULEMINCPC_IDX = 24;
export const METRIC_FILTER_RULEMAXCPC_IDX = 25;
export const METRIC_FILTER_RULECLICK_IDX = 26;
export const METRIC_FILTER_RULECR_IDX = 27;
export const METRIC_FILTER_RULECPCINCR_IDX = 28;
export const METRIC_FILTER_RELEVANCYSALE_IDX = 29;
// Below Display Filters Are For Adgroups Table
export const DISPLAY_FILTER_CMP_NAME_STATE = 30;
export const DISPLAY_FILTER_AG_GROUP_NAME = 31;
export const DISPLAY_FILTER_BID_STATE = 32;
export const DISPLAY_FILTER_UNIT_PRICE_MAX_ACOS = 33;
export const DISPLAY_FILTER_IMPR = 35;
export const DISPLAY_FILTER_CLICKS_CTR = 36;
export const DISPLAY_FILTER_SPEND_CPC = 37;
export const DISPLAY_FILTER_ORD7_ORDSKU7 = 38;
export const DISPLAY_FILTER_ORD30_ORDSKU30 = 39;
export const DISPLAY_FILTER_SALES7_SALESSKU7 = 40;
export const DISPLAY_FILTER_SALES30_SALESSKU30 = 41;
export const DISPLAY_FILTER_CR7_ACOS7 = 42;
export const DISPLAY_FILTER_CR30_ACOS30 = 43;
// export const DISPLAY_FILTER_CTR_CLICKS = 43;
// export const DISPLAY_FILTER_CPC_SPEND = 44;
// export const DISPLAY_FILTER_ORDSKU7_ORD7 = 45;
// export const DISPLAY_FILTER_ORDSKU30_ORD30 = 46;
// export const DISPLAY_FILTER_SALESSKU7_SALES7 = 47;
// export const DISPLAY_FILTER_SALESSKU30_SALES30 = 48;
// export const DISPLAY_FILTER_ACOS7_CR7 = 49;
// export const DISPLAY_FILTER_ACOS30_CR30 = 50;
// other hidden fields needed for export
export const DISPLAY_FILTER_AD_GROUP_ID = 51;
export const DISPLAY_FILTER_CAMP_ID = 52;
export const DISPLAY_FILTER_PROFIT_7 = 53;
export const DISPLAY_FILTER_PROFIT_30 = 54;
export const DISPLAY_FILTER_CAMP_STATE = 55;
export const DISPLAY_FILTER_CAMP_TARGETING = 56;
export const DISPLAY_FILTER_RULE_ACTIVE = 57;
export const DISPLAY_FILTER_CR7_CR30 = 58;
export const DISPLAY_FILTER_ACOS7_ACOS30 = 59;
// adGroupId campaignId profit7 profit30	 campaignState campaignTargeting ruleActive

export const SORT_MAPPING_COL_INDEX = [];
SORT_MAPPING_COL_INDEX[DISPLAY_FILTER_CMP_NAME_STATE] = CAMPAIGN_NAME_IDX;
SORT_MAPPING_COL_INDEX[DISPLAY_FILTER_BID_STATE] = METRIC_FILTER_DEFAULTBID_IDX;


export const METRICS_FILTER_MAPPED_IDX = {
  defaultBid: METRIC_FILTER_DEFAULTBID_IDX,
  spend: METRIC_FILTER_SPEND_IDX,
  acos7: METRIC_FILTER_ACOS7_IDX,
  acos30: METRIC_FILTER_ACOS30_IDX,
  cr7: METRIC_FILTER_CR7_IDX,
  cr30: METRIC_FILTER_CR30_IDX,
  ctr: METRIC_FILTER_CTR_IDX,
  cpc: METRIC_FILTER_CPC_IDX,
  impr: METRIC_FILTER_IMPRESS_IDX,
  clicks: METRIC_FILTER_CLICKS_IDX,
  orders30: METRIC_FILTER_C30D_IDX,
  ordersSKU30: METRIC_FILTER_C30DS_IDX,
  orders7: METRIC_FILTER_C7D_IDX,
  ordersSKU7: METRIC_FILTER_C7DS_IDX,
  sales7: METRIC_FILTER_S7D_IDX,
  salesSKU7: METRIC_FILTER_S7DS_IDX,
  sales30: METRIC_FILTER_S30D_IDX,
  salesSKU30: METRIC_FILTER_S30DS_IDX,
  maxAcos: METRIC_FILTER_MAXACOS_IDX,
  unitPrice: METRIC_FILTER_UNITPRICE_IDX,
  chRate:METRIC_FILTER_CHRATE_IDX,
  ruleMinCpc: METRIC_FILTER_RULEMINCPC_IDX,
  ruleMaxCpc: METRIC_FILTER_RULEMAXCPC_IDX,
  ruleClick: METRIC_FILTER_RULECLICK_IDX,
  ruleCr: METRIC_FILTER_RULECR_IDX,
  ruleCpcIncrement: METRIC_FILTER_RULECPCINCR_IDX,
  relevancySale: METRIC_FILTER_RELEVANCYSALE_IDX
};

export const EXPORT_HIDDEN_COLS_MAPPED = {
  adGroupId: DISPLAY_FILTER_AD_GROUP_ID,
  campaignId: DISPLAY_FILTER_CAMP_ID,
  profit7: DISPLAY_FILTER_PROFIT_7,
  profit30: DISPLAY_FILTER_PROFIT_30,
  campaignState: DISPLAY_FILTER_CAMP_STATE,
  campaignTargeting: DISPLAY_FILTER_CAMP_TARGETING,
  ruleActive: DISPLAY_FILTER_RULE_ACTIVE,
};


// Define filtering list
export const filterList = [];
filterList[CREATED_AT_IDX] = [];
filterList[STATE_IDX] = [];
filterList[CAMPAIGN_NAME_IDX] = [];
filterList[LEGACY_AUTOMATION_IDX] = [];
filterList[METRIC_FILTER_DEFAULTBID_IDX] = [];
filterList[METRIC_FILTER_SPEND_IDX] = [];
filterList[METRIC_FILTER_ACOS7_IDX] = [];
filterList[METRIC_FILTER_ACOS30_IDX] = [];
filterList[METRIC_FILTER_CR7_IDX] = [];
filterList[METRIC_FILTER_CR30_IDX] = [];
filterList[METRIC_FILTER_CTR_IDX] = [];
filterList[METRIC_FILTER_CPC_IDX] = [];
filterList[METRIC_FILTER_IMPRESS_IDX] = [];
filterList[METRIC_FILTER_CLICKS_IDX] = [];
filterList[METRIC_FILTER_C30D_IDX] = [];
filterList[METRIC_FILTER_C30DS_IDX] = [];
filterList[METRIC_FILTER_C7D_IDX] = [];
filterList[METRIC_FILTER_C7DS_IDX] = [];
filterList[METRIC_FILTER_S7D_IDX] = [];
filterList[METRIC_FILTER_S7DS_IDX] = [];
filterList[METRIC_FILTER_S30D_IDX] = [];
filterList[METRIC_FILTER_S30DS_IDX] = [];
filterList[METRIC_FILTER_MAXACOS_IDX] = [];
filterList[METRIC_FILTER_UNITPRICE_IDX] = [];
filterList[METRIC_FILTER_RULEMINCPC_IDX] = [];
filterList[METRIC_FILTER_RULEMAXCPC_IDX] = [];
filterList[METRIC_FILTER_RULECLICK_IDX] = [];
filterList[METRIC_FILTER_RULECR_IDX] = [];
filterList[METRIC_FILTER_RULECPCINCR_IDX] = [];
filterList[METRIC_FILTER_RELEVANCYSALE_IDX] = [];

// https://www1.zon.tools/sandbox/dashboard/productgroup.php

// https://www1.zon.tools/DE-304373076738316/dashboard/ajax.php?headers=localhost
export const API_URL = "https://www1.zon.tools/DE-304373076738316/dashboard/ajax.php?headers=audit"
// export const API_URL = "https://www1.zon.tools/api/dashboard/";
// export const API_TOKEN = '3f60153595c16767a7187d0e82eecd54d00e24d8c619db902fcd56c868b6e216&headers=localhost2';

// export const API_URL_ADGROUP = `${API_URL}adgroup.php?token=${API_TOKEN}`;
// export const API_URL_KEYWORD = `${API_URL}keyword.php?token=${API_TOKEN}`;
